import { useEffect } from "react";
import { MaterialSymbol } from "../modules/MaterialSymbol";
import { Link } from "react-router-dom";

import TextBox, { Button } from "../modules/Inputs";
import { register } from "../scripts/register";

// Images
import openai_logo from "../../assets/images/openai.png";
import source_docs from "../../assets/images/source_docs.png";
import dd_outputs from "../../assets/images/dd_outputs.png";

// Styles
import "../App.css";
import "../../utilities.css";
import "../modules/HeroCard.css";
import Footer from "../modules/Footer";

export function Home({}) {
	// Set window title
	useEffect(() => {
		document.title = "deepdive | AI-powered acquisition diligence";
	});

	const icon_size = 200;
	return (
		<div className="hero-container">
			<div className="hero-cards">
				{/* Main hero card with registration box */}
				<div className="herocard-container u-primary-gradient-bg">
					<div className="hero-image">
						<img
							src={source_docs}
							className="hero-image-source"
							alt="Source diligence documents: CIM and financial statements"
						/>
						<MaterialSymbol name="trending_flat" size={icon_size * 0.6} />
						<img
							src={dd_outputs}
							className="hero-image-outputs"
							alt="Deepdive outputs: LOI memo and Excel financial model"
						/>
					</div>
					<div className="hero-multi-text" id="register">
						<h3>AI-powered acquisition diligence</h3>
						<h1>Create models and memos in minutes</h1>
						<p>
							Use generative AI to draft preliminary diligence memos and build
							financial models from just a few inputs.
						</p>
						<p>
							Save time, focus your efforts, and look at more deals. Add{" "}
							<b>deepdive</b> to your diligence team.
						</p>
						<div className="register-container">
							<TextBox
								className="email-register-input"
								id="email-input"
								email={true}
								placeholder="Leave your email. We'll get in touch"
								novalidate={true}
								onKeyUp={(event) => {
									if (event.keyCode === 13 || event.key === "Enter")
										document.querySelector("#register_button").click();
									return false;
								}}
							/>
							<Button
								id="register_button"
								label="Register"
								onClick={() => register("#email-input", "#register-warning")}
							/>
							<div id="register-warning" className="register-result"></div>
						</div>
					</div>
				</div>
				{/* OpenAI GPT info */}
				<div className="herocard-container u-white-bg">
					<div className="hero-multi-text">
						<h1>Built on GPT</h1>
						<p>
							Leverage state-of-the-art OpenAI language models to rapidly
							digest, analyze, and summarize a wide array of company documents.
						</p>
						<p>
							Deepdive's models are continually training to improve performance
							and accuracy, providing enhanced insight and value over time.
						</p>
					</div>
					<div className="hero-image">
						<img src={openai_logo} alt="OpenAI logo" width={350} />
					</div>
				</div>
				{/* Add deal team horsepower */}
				<div className="herocard-container u-secondary-gradient-bg">
					<div className="hero-image">
						<MaterialSymbol name="group_add" size={icon_size} />
					</div>
					<div className="hero-multi-text">
						<h1>Effectively add deal team capacity</h1>
						<p>
							Supercharge your existing diligence team and create the capacity
							to look at more deals in more detail every day.
						</p>
						<p>
							Deepdive seamlessly integrates with your existing workflows and
							provides much-needed horsepower and support to your diligence
							efforts across the board.
						</p>
					</div>
				</div>
				{/* Secure and confidential */}
				<div className="herocard-container u-white-bg">
					<div className="hero-multi-text">
						<h1>Secure and confidential</h1>
						<p>
							Deepdive is built with security and confidentiality top of mind.
						</p>
						<p>
							We use encryption and access controls to ensure your data is
							protected, and you get a dedicated, private instance of deepdive
							for your firm.
						</p>
						<p>
							{" "}
							For more information, see our <Link to="/security">
								security
							</Link>{" "}
							FAQ.
						</p>
					</div>
					<div className="hero-image">
						<MaterialSymbol name="lock" size={icon_size} />
					</div>
				</div>
				{/* Collaborative and interactive */}
				<div className="herocard-container u-secondary-gradient-bg">
					<div className="hero-image">
						<MaterialSymbol name="magic_exchange" size={icon_size} />
					</div>
					<div className="hero-multi-text">
						<h1>Collaborative and interactive</h1>
						<p>
							Engage with deepdive to shape any aspect of the content it
							generates, or ask it follow-up questions about what it's written.
						</p>
						<p>
							Customize formatting, add or remove sections, or specify areas of
							diligence focus to investigate further.
						</p>
					</div>
				</div>
				{/* Coming soon */}
				<div className="herocard-container u-white-bg">
					<div className="coming-soon">
						<h1>Coming soon</h1>
						<p>
							We're currently in private alpha. If you're interested in joining
							our waitlist, please leave your email below or{" "}
							<a href="mailto:tim@deepdiveai.ca">contact us</a>.
						</p>
						<div className="register-container register-bottom">
							<TextBox
								className="email-register-input"
								id="email-input-bottom"
								email={true}
								placeholder="Leave your email. We'll get in touch"
								novalidate={true}
								onKeyUp={(event) => {
									if (event.keyCode === 13 || event.key === "Enter")
										document.querySelector("#register_button_bottom").click();
									return false;
								}}
							/>
							<Button
								id="register_button_bottom"
								label="Register"
								onClick={() =>
									register("#email-input-bottom", "#register-warning-bottom")
								}
							/>
							<div
								id="register-warning-bottom"
								className="register-result"
							></div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Home;
