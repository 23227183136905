import React from "react";
import "./Inputs.css";

/**
 * A text input styled for this site.
 *
 * @param {string} label - The label for the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {string} value - The value of the input.
 * @param {function} onChange - The function to call when the input changes.
 * @param {function} onKeyUp - The function to call when a key is pressed.
 * @param {string} className - The class name for the input.
 * @param {string} id - The id for the input.
 * @param {boolean} email - Whether or not the input is an email input.
 *
 */
function TextBox(props) {
	return (
		<input
			type={props.email ? "email" : "text"}
			className={
				(props.email ? "input-email" : "input-text") +
				" " +
				props.className
			}
			placeholder={props.placeholder}
			value={props.value}
			onChange={props.onChange}
			onKeyUp={props.onKeyUp}
			id={props.id}
			required={props.required}
			noValidate={props.novalidate}
		/>
	);
}

/**
 * A button styled for this site.
 *
 * @param {string} label - The label for the button.
 * @param {function} onClick - The function to call when the button is clicked.
 */
export function Button(props) {
	return (
		<button
			type="button"
			className="button-input"
			onClick={props.onClick}
			id={props.id}
		>
			{props.label}
		</button>
	);
}

/**
 * An input submit button styled for this site.
 * @param {string} label - The label for the button.
 * @param {string} onSubmit - The function to call when the button is clicked.
 * @param {string} className - The class name for the button.
 * @param {string} id - The id for the button.
 */
export function SubmitButton(props) {
	return (
		<input
			type="submit"
			className={"button-input " + props.className}
			value={props.label}
			onClick={props.onSubmit}
			id={props.id}
		/>
	);
}

export default TextBox;
