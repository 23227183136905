import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Styles
import "./App.css";
import "../utilities.css";
import "./modules/HeroCard.css";

// Pages
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";

// Components
import Navbar from "./modules/Navbar";
import Security from "./pages/Security";

function App() {
	return (
		<div>
			<Navbar />
			<div className="app-container">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="security" element={<Security />} />
					<Route path="*" element={<Home />} /> {/* Default route */}
				</Routes>
			</div>
		</div>
	);
}

export default App;
