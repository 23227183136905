import "../../utilities.css";

/** A MaterialIcon component
 * @param {string} name - The name of the icon to display
 * @param {string} size - The size of the icon
 * @param {string} color - The color of the icon
 */
export function MaterialSymbol(props) {
	return (
		<span
			className="material-symbols-outlined"
			style={{ fontSize: props.size + "px" }}
		>
			{props.name}
		</span>
	);
}
