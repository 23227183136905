import React from "react";

// Library components
import { Button } from "./Inputs";
import { Link } from "react-router-dom";

// Styles
import "./Navbar.css";
import "../../utilities.css";

/** Navbar with company logo at left and buttons at right */
export default function Navbar(props) {
	return (
		<div className="navbar-container">
			<div className="navbar-logo">
				<Link to={"/"}>deepdive</Link>
			</div>
			<div className="navbar-buttons">
				<Link className="navbar-button" to="/">
					Home
				</Link>
				<Link className="navbar-button" to="/security">
					Security
				</Link>
				<Link to="mailto:tim@deepdiveai.ca" className="navbar-button">
					Contact
				</Link>
				<Button
					label="Register"
					onClick={(event) => {
						window.location.href = "/#register";
						setTimeout(() => {
							document.querySelector("#email-input").focus();
						}, 100);
					}}
				/>
			</div>
		</div>
	);
}
