import { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Privacy.css";
import Footer from "../modules/Footer";

export function Privacy({}) {
	// Set window title
	useEffect(() => {
		document.title = "deepdive | Privacy Statement";
	});

	return (
		<div className="privacy-statement">
			<h1>Privacy Statement</h1>
			<p>
				<em>Updated as of July 7, 2023</em>
			</p>
			<p>
				Deepdive Software Inc. (collectively, <b>“we”</b>, <b>“our”</b> or{" "}
				<b>“us”</b>) recognize the importance of privacy. The purpose of this
				privacy statement (“Privacy Statement”) is to inform you about our
				privacy practices, including how we collect, use and disclose your
				Personal Information.
			</p>
			<p>
				This Privacy Statement applies to the collection, use and disclosure of
				personal information through the services we provide and through our
				interactions with customers and potential customers (collectively, the{" "}
				<b>“Services”</b>). This Privacy Statement applies to all of our
				operations in Canada or operations in connection with Canada, unless we
				have provided you with a separate privacy statement for a particular
				product, service or activity.
			</p>
			<h3>Privacy Statement Updates</h3>
			<p>
				This Privacy Statement is current as of the “updated” date which appears
				at the top of this page. We may modify this Privacy Statement from time
				to time. When changes are made to this Privacy Statement, they will
				become immediately effective when published in a revised Privacy
				Statement, notice of which shall be directly sent to you. We may also
				communicate the changes by other means, such as a public notice on our
				website.
			</p>
			<p>
				Please review this Privacy Statement carefully. By submitting your
				Personal Information to us, by registering for or using the Services, or
				by voluntarily interacting with us, you consent to our collecting, using
				and disclosing your Personal Information as set out in this Privacy
				Statement, as revised from time to time.
			</p>
			<h3>What's in this Privacy Statement?</h3>
			<p>This Privacy Statement covers the following topics:</p>
			<ul>
				<li>Meaning of Personal Information</li>
				<li>
					Your Consent to Collection, Use and Disclosure of Personal Information
				</li>
				<li>Personal Information We Collect</li>
				<li>How We Use Your Personal Information</li>
				<li>How We Share Your Personal Information</li>
				<li>Retention of Personal Information</li>
				<li>Information Security</li>
				<li>Accessing and Updating Your Personal Information</li>
				<li>International Transfer and Storage of Information</li>
				<li>Third Party Websites and Services</li>
				<li>How to Contact Us</li>
			</ul>
			<h3>Meaning of Personal Information</h3>
			<p>
				<b>"Personal Information"</b> means information about an identifiable
				individual as described under Canadian privacy laws. This information
				may include, but is not limited to, your name, mailing address, country
				of residence, e-mail address, and telephone.
			</p>
			<p>
				Personal Information does not include any business contact information
				that is solely used to communicate with you in relation to your
				employment, business or profession, such as your name, position name or
				title, work address, work telephone number, work fax number or work
				electronic address.
			</p>
			<p>
				Personal Information also does not include information that has been
				anonymized or aggregated in such a way that there is no serious
				possibility it can be used to identify an individual, whether on its own
				or in combination with other information.
			</p>
			<h3>
				Your Consent to Collection, Use and Disclosure of Personal Information
			</h3>
			<p>
				We collect, use and disclose your Personal Information with your consent
				or as permitted or required by Canadian privacy laws. How we obtain your
				consent (i.e., the form we use) will depend on the circumstances, as
				well as the sensitivity of the information collected. Subject to
				applicable laws, your consent may be express or implied, depending on
				the circumstances and the sensitivity of the Personal Information in
				question.
			</p>
			<p>
				If you choose to provide Personal Information to us, we assume that you
				consent to the collection, use and disclosure of that Personal
				Information as outlined in this Privacy Statement.
			</p>
			<p>
				If you wish to withdraw your consent to our collection, use or
				disclosure of your Personal Information, please contact us using the
				contact information in the “How to Contact Us” section below. However,
				before we implement the withdrawal of consent, we may require proof of
				your identity. In some cases, withdrawal of your consent may mean that
				we will no longer be able to provide certain products or services.
			</p>
			<p>
				If you provide Personal Information about another individual to us, it
				is your responsibility to obtain the consent of that individual to
				enable us to collect, use and disclose his or her information as
				described in this Privacy Statement.
			</p>
			<h3>Personal Information We Collect</h3>
			<p>
				The Personal Information we collect is generally in one or more of the
				following categories.
			</p>
			<ul>
				<li>
					<u>
						<i>Contact Information.</i>
					</u>{" "}
					For individuals located in Canada who use our products and services,
					we may collect information from you or from your use of our products
					or services, or Personal Information you submit to use to register for
					our Services, such as your name, e-mail address, and other contact
					information.
				</li>
				<li>
					<u>
						<i>Usage Data.</i>
					</u>{" "}
					For individuals located in Canada who use the Services, we may
					automatically collect usage data that is identifiable with you or from
					your activities on the Services. We may collect certain technical and
					device information about your use of the Services. Such information
					may include your Internet protocol address, information about your
					device, browser and operating system, and the date and time of your
					visit.
				</li>
				<li>
					<u>
						<i>Aggregated Information.</i>
					</u>{" "}
					We will aggregate and anonymize certain data collected in connection
					with your use of our products and services, which may be stored and
					used separately from the data and Personal Information referred to
					above.
				</li>
				<li>
					<u>
						<i>Website.</i>
					</u>{" "}
					For individuals located in Canada who visit our website located at{" "}
					<Link to="https://deepdiveai.ca/#">deepdiveai.ca</Link> or any of our
					related websites (collectively, “our website”), we may collect
					information from you or from your activities on the site:
					<ul>
						<li>
							Like most websites and other Internet services, we may collect
							certain technical and device information about your use of our
							website, which may include your Internet protocol address,
							information about your device, browser and operating system, and
							the date and time of your visit, as well as the region or general
							location where your device is accessing the internet, and other
							information about the usage of our website, including a history of
							the pages you view.
						</li>
						<li>
							We may utilize "cookies" that are technically necessary and that
							increase the functionality of using our products and services. In
							addition to cookies, we may use web beacons, and similar
							technologies when you use our products and services for our
							business or analytical purposes, such as assessing traffic on our
							website and understanding how customers utilize our products and
							services. A “cookie” is a small text file that contains
							information and is stored on your computer. One type of cookie
							saves a file permanently on your computer. Cookies will be used to
							personalize a web page according to the user's choices and
							interests. The other main type of cookie is called a “session
							cookie”. While you are visiting a web page, session cookies are
							sent between your computer and the server to access information.
							Session cookies disappear when you turn off your web browser. If
							you do not wish to accept cookies, your web browser can be set so
							that you automatically deny the storage of cookies or are informed
							every time a website requests to be allowed to store a cookie.
							Previously stored cookies can also be deleted through the web
							browser. Please refer to your web browser's help pages for more
							information. However, please be aware that some areas or features
							of our products and services rely on cookies and so may not work
							if you delete or decline cookies. For more information about
							cookies generally please visit{" "}
							<Link to="http://www.allaboutcookies.org">
								www.allaboutcookies.org
							</Link>
							.
						</li>
						<li>
							If you enter information in the “Contact Us” section, we collect
							your name, email address, phone number, company you represent and
							details of your inquiry to enable us to respond to a
							general/business inquiry made by you on behalf of your company.
						</li>
					</ul>
				</li>
				<li>
					<u>
						<i>Other Interactions.</i>
					</u>{" "}
					For individuals located in Canada who otherwise interact with us,
					whether in person, by phone or e-mail, through social media or
					otherwise, including individuals who might be interested in acquiring
					our products or services, who sign-up to receive newsletters or other
					communications, or who respond to surveys and questionnaires we may
					send out from time to time, we may collect information that you
					provide to us during these interactions. This information may include
					your name, e-mail address, demographic information (such as your
					location, gender and age range), professional information (such as
					your professional designations and name of your employer), other
					information relevant and related to your use and interaction with our
					products and services, and any other information you may elect to
					provide to us during these interactions.
				</li>
			</ul>
			<h3>How We Use Your Personal Information</h3>
			<p>We may use your Personal Information for purposes such as:</p>
			<ul>
				<li>
					providing you with our Services and supporting your use of our
					Services;
				</li>
				<li>contacting you relating to our Services;</li>
				<li>
					Monitoring the usage of our Services to support their proper
					functioning and further improvement;
				</li>
				<li>
					Analyzing the needs and activities of our customers to help us better
					serve them;
				</li>
				<li>
					Generating and displaying de-identified and aggregated data to conduct
					research and analysis related to our business, products, and services;
				</li>
				<li>Responding to inquiries and other requests;</li>
				<li>Developing marketing materials;</li>
				<li>Collecting opinions and comments about our Services; and</li>
				<li>Investigating legal claims.</li>
			</ul>
			<p>
				We may use your Personal Information for purposes for which we have
				obtained your consent, and for such other purposes as may be permitted
				or required by applicable Canadian privacy laws. We will process
				collect, use, and disclose information received through our website only
				to pursue our legitimate business interests in keeping you updated on
				our services and products, respond to your queries, establish
				communication with you and your company, and to enhance user experience
				by analyzing your use of our website.
			</p>
			<p>
				We do not use the information we collect to advertise third party
				products and services or targeted advertising of our products and
				services across third party websites or service offerings.
			</p>
			<h3>How We Share Your Personal Information</h3>
			<p>
				We rely on third party services providers to perform a variety of
				services on our behalf, such as payment card processers, email and
				communication marketing service providers, hosting, data storage and
				processing service providers, and research and analytics providers. Your
				Personal Information may also become available to our analytical
				vendors, as well as search engine and performance optimization services.
			</p>
			<p>
				If we provide your information to service providers, then we require
				that the service providers keep your Personal Information secure, and
				only handle it for limited purposes for which it is provided. We do not
				authorize the service providers to disclose your Personal Information to
				unauthorized parties or to use your Personal Information for their
				direct marketing purposes. If you would like more information about our
				service providers, please contact us using the contact information in
				the “How to Contact Us” section below.
			</p>
			<p>
				Additionally, we may use and disclose your information when we believe
				such use or disclosure is permitted, necessary or appropriate: (a) under
				applicable law, including laws outside your country of residence; (b) to
				comply with legal process; (c) to respond to requests from public and
				government authorities, including public and government authorities
				outside your country of residence; (d) to enforce the terms of the
				agreements for our products and services; (e) to protect our rights,
				operations or property; (f) to allow us to pursue available remedies or
				limit the damages that we may sustain. In addition, we may transfer your
				Personal Information and other information to a third party in the event
				of any reorganization, merger, sale, joint venture, assignment, transfer
				or other disposition of all or any portion of our business, brands,
				affiliates, subsidiaries or other assets.
			</p>
			<p>
				If we otherwise intend to disclose your Personal Information to a third
				party, we will identify that third party and the purpose for the
				disclosure and obtain your consent.
			</p>
			<p>
				Notwithstanding the above, we may share information that does not
				identify you (including information that has been aggregated or
				de-identified) with third parties except as prohibited by applicable
				law.
			</p>
			<h3>Opting Out of Marketing Communications</h3>
			<p>
				If you no longer want to receive marketing-related emails from us, you
				may opt-out of receiving emails by clicking the “unsubscribe” link at
				the bottom of any email you receive from us. You may also opt-out by
				contacting us directly using the contact information in the “How to
				Contact Us” section below.
			</p>
			<p>
				We will endeavour to respond to your opt-out request promptly, but we
				ask that you please allow us a reasonable time to process your request.
				Please note that if you opt-out from receiving marketing-related emails,
				we may still need to send you communications about your use of our
				products or services, or other matters.
			</p>
			<h3>Retention and Storage of Personal Information</h3>
			<p>
				We will use, disclose or retain your Personal Information only for as
				long as necessary to fulfill the purposes for which that Personal
				Information was collected and as permitted or required by Canadian
				privacy laws.
			</p>
			<p>
				We store all Personal Information in Canada or the United States,
				depending upon where you are accessing the Services from.
			</p>
			<p>
				However, if you access or use our products and services outside of
				Canada, you may be subject to different data protection rules than those
				in Canada. While your Personal Information is outside of Canada, it is
				subject to the laws of the country in which it is located, and those
				laws may require disclosure of your Personal Information to authorities
				in that country. For written information about our policies and
				practices regarding service providers outside of Canada, contact us at
				the email address below.
			</p>
			<h3>Information Security</h3>
			<p>
				We have implemented physical, organizational, contractual and
				technological security measures with a view to protecting your Personal
				Information and other information from loss or theft, unauthorized
				access, disclosure, copying, use or modification. We have taken steps to
				ensure that the only personnel who are granted access to your Personal
				Information are those with a business 'need-to-know' or whose duties
				reasonably require such information.
			</p>
			<p>
				Despite the measure outlined above, no method of information
				transmission or information storage is 100% secure or error-free, so we
				unfortunately cannot guarantee absolute security. If you have reason to
				believe that your interaction with us is no longer secure (for example,
				if you feel that the security of any information that you provided to us
				has been compromised), please contact us immediately using the contact
				information in the “How to Contact Us” section below.
			</p>
			<h3>Accessing and Updating Your Personal Information</h3>
			<p>
				We expect you, from time to time, to supply us with updates to your
				Personal Information, when required. We will not routinely update your
				Personal Information, unless such a process is necessary.
			</p>
			<p>
				You may make a written request to review any Personal Information about
				you that we have collected, used or disclosed, and we will provide you
				with any such Personal Information to the extent required by applicable
				laws. You may also challenge the accuracy or completeness of your
				Personal Information in our records. If you successfully demonstrate
				that your Personal Information in our records is inaccurate or
				incomplete, we will amend the Personal Information as required.
			</p>
			<p>
				In certain circumstances, we may not be able to provide you access to
				all of your Personal Information it holds, such as where it is
				prohibitively costly to provide, where it contains references or
				Personal Information of other individuals, or for security, proprietary
				or legal reasons, including where the information is subject to
				solicitor-client or litigation privilege. We will provide you the
				reasoning and rationale for any denial, in whole or in part, of any
				request for access to your Personal Information we may hold.
			</p>
			<p>
				We may require that you provide sufficient identification to fulfill
				your request to access or correct your Personal Information. Any such
				identifying information will be used only for this purpose. We will not
				charge you any fees to access your personal information in our records
				without first providing you with an estimate of the approximate fees, if
				any.
			</p>
			<p>
				If you object to how we handle your request, you may have the right to
				make a complaint to the Privacy Commissioner of Canada or the applicable
				provincial privacy commissioner.
			</p>
			<h3>Third Party Websites and Services</h3>
			<p>
				We may provide links to third-party websites and services for your
				convenience and information. Those websites and services are governed by
				the privacy statements and policies of the respective third party. This
				Privacy Statement does not extend to any websites, products or services
				provided by third parties. We do not assume responsibility for the
				privacy practices of third parties and we encourage you to review all
				third-party privacy statements prior to using third-party websites,
				products or services.
			</p>
			<h3>How to Contact Us</h3>
			<p>
				All comments, questions, concerns or complaints regarding your Personal
				Information or our privacy practices should be sent to{" "}
				<Link to="mailto:privacy@deepdiveai.ca">privacy@deepdive.ca</Link>.
			</p>
			<Footer />
		</div>
	);
}

export default Privacy;
