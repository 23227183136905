import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Security.css";

function Security({}) {
	// Set window title
	useEffect(() => {
		document.title = "deepdive | Security";
	});

	return (
		<div className="security-faq">
			<h1>Security at Deepdive</h1>

			<p>
				At Deepdive, we recognize that our clients entrust us with sensitive and
				critical information. Understanding the intricacies and responsibilities
				that come with managing private equity data, we have crafted a
				multi-layer security framework. Our security measures encompass
				encryption, access controls, data segregation, secure data transmission,
				and compliance with regulatory requirements.
			</p>
			<p>
				As part of our commitment to transparency and customer engagement, we
				have put together a set of Frequently Asked Questions. These questions
				address common inquiries and concerns regarding our security measures.
				We encourage you to go through them, and should you have any further
				questions or need additional information,{" "}
				<Link to="mailto:tim@deepdiveai.ca">reach out to us</Link>.
			</p>

			<h4>
				Q1: Where are my files hosted, and how secure is the hosting
				environment?
			</h4>
			<p>
				Answer: Your files are hosted on Google Cloud Storage, in separate
				buckets allocated exclusively for your firm. The files are encrypted
				both at rest and in transit, ensuring a high level of security. In
				addition, we've implemented strict access controls and permissions to
				ensure that only authorized users can access these files.
			</p>

			<h4>
				Q2: Does Deepdive offer on-premise hosting as an alternative to cloud
				hosting?
			</h4>
			<p>
				Answer: Currently, Deepdive uses cloud hosting for optimal performance
				and scalability. We understand that some clients may prefer on-premise
				solutions for various reasons. If this is a requirement for you, please
				reach out to our team to discuss possible solutions.
			</p>

			<h4>
				Q3: How are my generated documents like models and memos segregated from
				other clients' data?
			</h4>
			<p>
				Answer: Each firm's output data is isolated in a separate encrypted
				database instance. This approach ensures data segregation and prevents
				data leakage between clients. Furthermore, strict access controls are
				applied to these databases, similar to file storage.
			</p>

			<h4>
				Q4: Is my data sent to a third-party API? If so, how is it secured?
			</h4>
			<p>
				Answer: Yes, excerpts from your files and generated outputs are sent to
				OpenAI's GPT API for processing. All API submissions are encrypted, and
				unique API keys are used for each firm, ensuring accountability and
				security.
			</p>

			<h4>Q5: Are my files or data being used to train AI models?</h4>
			<p>
				Answer: By default, your data is not used for training any AI models.
				However, you have the option to opt-in if you wish to utilize your data
				for training firm-specific models to capture writing style and beyond.
				Any model trained with your data remains private to your firm and is
				never shared with other parties.
			</p>

			<h4>Q8: How does Deepdive handle data residency?</h4>
			<p>
				Answer: Deepdive ensures that data is stored in regions compliant with
				our customers' legal and regulatory requirements. If you have specific
				data residency requirements, please let us know, and we will work to
				accommodate them.
			</p>

			<h4>
				Q9: Can I request to delete files, data, and fine-tuned firm-specific
				models? What is the process?
			</h4>
			<p>
				Answer: Yes, absolutely. Clients have full control over their files,
				data, and fine-tuned models. You can easily and permanently delete any
				or all of these directly through the Deepdive web application.
			</p>
		</div>
	);
}

export default Security;
