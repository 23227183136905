import { Link } from "react-router-dom";

export function Footer({}) {
	return (
		<div className="hero-footer u-flex-column">
			<Link to="privacy">Privacy Statement</Link>
			&copy; 2023 Deepdive Software Inc. All rights reserved.
		</div>
	);
}

export default Footer;
