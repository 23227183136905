import {
	addClass,
	removeClass,
	hideElement,
	showElement,
	validateEmail,
	post,
} from "./utilities";

var animationTimeout = 0;
var hideTimeout = 0;

export function register(input_id, warning_id) {
	var displayTime = 4000;
	var registerWarning = document.querySelector(warning_id);
	var emailInput = document.querySelector(input_id);
	removeClass(registerWarning, "invalid");
	removeClass(registerWarning, "success");
	removeClass(registerWarning, "warning");
	registerWarning.style.animation = "none";

	// Clear any existing timeouts
	if (animationTimeout !== 0) {
		clearTimeout(animationTimeout);
	}
	if (hideTimeout !== 0) {
		clearTimeout(hideTimeout);
	}
	if (!validateEmail(emailInput.value)) {
		// Get register-warning and show invalid email alert
		addClass(registerWarning, "invalid");
		registerWarning.innerHTML = "Invalid email";
		showElement(registerWarning);
		animationTimeout = setTimeout(() => {
			registerWarning.style.animation = "fadeOut ease-out 1s";
		}, displayTime);
		hideTimeout = setTimeout(() => {
			hideElement(registerWarning);
		}, displayTime + 900);
	} else {
		var email = emailInput.value.trim().toLowerCase();
		post("/api/email/subscriber", { email: email }).then((res) => {
			if (res.success === 1) {
				// Style and display success message
				addClass(registerWarning, "success");
				registerWarning.innerHTML = "Registered " + email;
				// Set email input to empty
				emailInput.value = "";

				// Send registration email
				post("/api/email/send-registration-email", {
					to_email: email,
				});
			} else if (res.data === "Subscriber already exists") {
				// Style and display success message
				addClass(registerWarning, "success");
				registerWarning.innerHTML = "Registered " + email;
				// Set email input to empty
				emailInput.value = "";
			}

			// Show success or warning message and animate
			showElement(registerWarning);
			animationTimeout = setTimeout(() => {
				registerWarning.style.animation = "fadeOut ease-out 1s";
			}, displayTime);
			hideTimeout = setTimeout(() => {
				hideElement(registerWarning);
			}, displayTime + 900);
		});
	}
}
